import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Features : React.FC = () => {
  const FeaturesQueryData = useStaticQuery(graphql`
    query FeaturesQuery {
      Icon1: file(name: { eq: "icon-01" }, absolutePath: { regex: "/images/life-time-deal/features1/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Icon2: file(name: { eq: "icon-02" }, absolutePath: { regex: "/images/life-time-deal/features1/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Icon3: file(name: { eq: "icon-03" }, absolutePath: { regex: "/images/life-time-deal/features1/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Icon4: file(name: { eq: "icon-04" }, absolutePath: { regex: "/images/life-time-deal/features1/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Icon5: file(name: { eq: "icon-05" }, absolutePath: { regex: "/images/life-time-deal/features1/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Icon6: file(name: { eq: "icon-06" }, absolutePath: { regex: "/images/life-time-deal/features1/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Icon7: file(name: { eq: "icon-07" }, absolutePath: { regex: "/images/life-time-deal/features1/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Icon8: file(name: { eq: "icon-08" }, absolutePath: { regex: "/images/life-time-deal/features1/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Icon9: file(name: { eq: "icon-09" }, absolutePath: { regex: "/images/life-time-deal/features1/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }
      
    }
  `);

  const icon1 = getImage(FeaturesQueryData.Icon1);
  const icon2 = getImage(FeaturesQueryData.Icon2);
  const icon3 = getImage(FeaturesQueryData.Icon3);
  const icon4 = getImage(FeaturesQueryData.Icon4);
  const icon5 = getImage(FeaturesQueryData.Icon5);
  const icon6 = getImage(FeaturesQueryData.Icon6);
  const icon7 = getImage(FeaturesQueryData.Icon7);
  const icon8 = getImage(FeaturesQueryData.Icon8);
  const icon9 = getImage(FeaturesQueryData.Icon9);


  const itemsList = [
    {
      title: "Build Audience",
      paragraph:
        "Create lead capture forms to grab visitor’s attention and grow your audience base.",
      Icon: icon1,
    },
    {
      title: "Reduce Cart Abandonment",
      paragraph:
        "Display exit popups with this free exit popup generator and stop visitors from abandoning their shopping carts.",
      Icon: icon2,
    },
    {
      title: "Increase Cart Value",
      paragraph:
        " Display special offers and coupon codes to increase your customer’s cart value.",
      Icon: icon3,
    },
    {
      title: "Promote Special Offers",
      paragraph:
        "Double your Black Friday sales or Holiday Season sales using pretty popups to promote special offers.",
      Icon: icon4,
    },{
      title: "Gamification Popups",
      paragraph:
        "Engage visitors and capture instant attention using game popups - spin the wheel popups and more on the way.",
      Icon: icon5,
    },{
      title: "Countdown Timers ",
      paragraph:
        "Increase your eCommerce sales seamlessly. Use countdown timer popups to create FOMO and promote impulsive buying.",
      Icon: icon6,
    },{
      title: "Conduct Surveys",
      paragraph:
        " Use exit survey popups to collect valuable inputs from web visitors and make informed decisions..",
      Icon: icon7,
    },{
      title: "Guide Website Visitors ",
      paragraph:
        "Redirect traffic to target pages. Use popups to guide web visitors to specific pages and increase conversions significantly.",
      Icon: icon8,
    },{
      title: "Notification Popups",
      paragraph:
        "Help visitors stay updated with your website happenings. Display important messages using notice popups.",
      Icon: icon9,
    },
  ];

  return (
    <>
      <section className="relative items-center justify-center bg-white pt-14 lg:pt-24 lg:pb-20">

          <div className="flex flex-col items-center w-full pb-5 mx-auto space-y-10 xl:w-4/5">
              <h2 className="text-xl font-semibold capitalize text-logo_blue md:text-2xl xl:text-3xl">
                  What is Optinly 
              </h2>
              <p className="text-sm font-normal text-center text-dark_black md:text-base">Optinly is a goal-based popup plugin designed to assist online stores and websites in increasing conversions. Optinly is the first of its type, offering you to select your marketing goals post which you can choose appropriate pop-up templates.</p>
          </div>


          <div className="w-4/5 pt-8 pb-10 mx-auto">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3 md:gap-x-10 gap-y-16 md:gap-y-7">
            {itemsList.map((item, index) => {
            return ( 
              <div key={index} className={`w-full px-4 py-6 flex flex-col items-left justify-center  bg-price_card_background rounded-md space-x-3`}>
                <div className='flex flex-row items-center px-6 space-x-2'>  
                  <div>
                    <GatsbyImage image={item.Icon} alt="image"  />
                  </div>
                  <div className='text-base font-semibold text-black'>{item.title}</div>
                </div> 
                <p className="px-2 py-2 text-sm font-normal text-left" style={{color:'#5E6063'}}>{item.paragraph}</p>
              </div>
              );
            })} 
            </div>
          </div>

      </section>
    </>
  );
};

export default Features;
