import React from 'react'
import PropTypes from 'prop-types'
import {StaticQuery, graphql} from 'gatsby'
import Header from './header'
import Menu from './menu'
import Footer from './footer'
import 'bootstrap/dist/css/bootstrap.min.css'
import './layout.scss'
import ScrollButton from './ScrollButton'
import IntegrationFooter from './Integrations/IntegrationFooter'
import LifetimeDealHeader from './lifetimedealheader'


if (typeof window !== 'undefined') {
    window.jQuery = window.$ = require('jquery');
    require('bootstrap');
}

const LifetimeDealLayout = ({children, className}) => {
    return (
        <>
            <div className={"wrapper " + (className ? className : '')}>
                <LifetimeDealHeader siteTitle="Optinly Lifetime Deal - Get It Now"/>
                <div>
                    {children}
                    <IntegrationFooter /> 
                </div>
            </div>
            <ScrollButton />
            
        </>
    )
}

LifetimeDealLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default LifetimeDealLayout
