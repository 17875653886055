import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import Layout from '../components/layout'
import Features from '../components/LifeTimeDeal/Features'
import HowOptinly from '../components/LifeTimeDeal/HowOptinly'
import TestimonialNewCard from '../components/LifeTimeDeal/TestimonialNewCard'
import Metatags from '../components/Metatags'
import SwiperSlider from '../components/SwiperSliderTestimonial'
import HomeCTA from '../images/caseyjones.png';

import SliderImg1 from '../images/popup-testimonial/jack-armstrong.jpg';
import SliderImg2 from '../images/popup-testimonial/raviprakash.jpg';
import SliderImg3 from '../images/popup-testimonial/tgrgte.png';
import SliderImg4 from '../images/popup-testimonial/tommmy.jpg';
import SliderImg5 from '../images/popup-testimonial/weblink.jpg';

import SliderImgHook1 from '../images/popup-integrates/1.png';
import SliderImgHook2 from '../images/popup-integrates/2.png';
import SliderImgHook3 from '../images/popup-integrates/3.jpg';
import SliderImgHook4 from '../images/popup-integrates/4.png';
import SliderImgHook5 from '../images/popup-integrates/5.png';
import SliderImgHook6 from '../images/popup-integrates/6.png';
import SliderImgHook7 from '../images/popup-integrates/7.png';
import SliderImgHook8 from '../images/popup-integrates/8.png';
import SliderImgHook9 from '../images/popup-integrates/9.png';
import SliderImgHook10 from '../images/popup-integrates/10.png';
import SliderImgHook11 from '../images/popup-integrates/11.png';
import SliderImgHook12 from '../images/popup-integrates/12.png';
import SliderImgHook13 from '../images/popup-integrates/13.png';
import SliderImgHook14 from '../images/popup-integrates/14.png';
import SliderImgHook15 from '../images/popup-integrates/15.png';
import SliderImgHook16 from '../images/popup-integrates/16.png';
import SliderImgHook17 from '../images/popup-integrates/17.png';
import SliderImgHook18 from '../images/popup-integrates/18.png';
import SliderImgHook19 from '../images/popup-integrates/19.png';
import SliderImgHook20 from '../images/popup-integrates/20.png';
import SliderImgHook21 from '../images/popup-integrates/21.png';
import SliderImgHook22 from '../images/popup-integrates/22.jpg';
import SliderImgHook23 from '../images/popup-integrates/23.png';
import SliderImgHook24 from '../images/popup-integrates/24.png';
import LifetimeDealLayout from '../components/LifetimedealLayout'
import Timer from '../components/CountDown/Timer'
import Countdown from '../components/CountDown'
import { useCountDown } from '../utils/CouterHook'


const LifeTimeDeal = () => {

    const [videoWidth, setVideoWidth] = useState()
    const [videoHeight, setVideoHeight] = useState()
    const [targetDate, setTargetDate] = useState()

    useEffect(()=> {
        setVideoWidth(document.getElementById('video-container')?.offsetWidth ?? 560)
        setVideoHeight((document.getElementById('video-container')?.offsetWidth / 2) + 50  ?? 370)
    }, [])


      useEffect(() => {
        const sixteenHoursInMs = 16 * 60 * 60 * 1000;
        const nowInMs = new Date().getTime()

        let time = parseInt(nowInMs + sixteenHoursInMs)

        let offTime = localStorage.getItem('ltd-off-time');

        if(!offTime) {
            localStorage.setItem('ltd-off-time', time);
            setTargetDate(time);
        }else {
            let newNowInMs  = new Date().getTime() - offTime
            let correctTime = parseInt(offTime + newNowInMs)
            setTargetDate(parseInt(correctTime));
            localStorage.setItem('ltd-off-time', parseInt(correctTime));
        }

      }, [])

    const data = useStaticQuery(graphql`
        query  {
            HomeCTA: file(name: {eq: "cta"}, absolutePath: {regex: "/images/life-time-deal/"}) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE)
            }
            }

            Rating: file(name: {eq: "rating"}, absolutePath: {regex: "/images/life-time-deal/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Platforms1: file(name: {eq: "platforms"}, absolutePath: {regex: "/images/life-time-deal/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Goal: file(name: {eq: "goal_based_templates"}, absolutePath: {regex: "/images/life-time-deal/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Advance: file(name: {eq: "advanced_training"}, absolutePath: {regex: "/images/life-time-deal/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Realtime: file(name: {eq: "real_time_analytics"}, absolutePath: {regex: "/images/life-time-deal/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Feature1: file(name: {eq: "01"}, absolutePath: {regex: "/images/life-time-deal/features/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Feature2: file(name: {eq: "02"}, absolutePath: {regex: "/images/life-time-deal/features/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Feature3: file(name: {eq: "03"}, absolutePath: {regex: "/images/life-time-deal/features/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Feature4: file(name: {eq: "04"}, absolutePath: {regex: "/images/life-time-deal/features/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Feature5: file(name: {eq: "05"}, absolutePath: {regex: "/images/life-time-deal/features/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Feature6: file(name: {eq: "06"}, absolutePath: {regex: "/images/life-time-deal/features/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Thumbnail1: file(name: {eq: "1"}, absolutePath: {regex: "/images/life-time-deal/video/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Thumbnail2: file(name: {eq: "2"}, absolutePath: {regex: "/images/life-time-deal/video/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Thumbnail3: file(name: {eq: "3"}, absolutePath: {regex: "/images/life-time-deal/video/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Thumbnail4: file(name: {eq: "4"}, absolutePath: {regex: "/images/life-time-deal/video/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Dots: file(name: {eq: "dots"}, absolutePath: {regex: "/images/life-time-deal/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            DragAndDrop: file(name: {eq: "drag-and-drop"}, absolutePath: {regex: "/images/life-time-deal/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            Empty: file(name: {eq: "empty"}, absolutePath: {regex: "/images/life-time-deal/"}) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            InsightIcon01: file(name: { eq: "insight-icon-01" }, absolutePath: { regex: "/images/life-time-deal/" }) {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }

            InsightIcon02: file(name: { eq: "insight-icon-02" }, absolutePath: { regex: "/images/life-time-deal/" }) {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }

            InsightIcon03: file(name: { eq: "insight-icon-03" }, absolutePath: { regex: "/images/life-time-deal/" }) {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }

            TestimonialNew: file(name: { eq: "testimonial_new_1" }, absolutePath: { regex: "/images/life-time-deal/" }) {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }

            Map: file(name: { eq: "map" }, absolutePath: { regex: "/images/life-time-deal/" }) {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }

            Integration: file(name: { eq: "integration" }, absolutePath: { regex: "/images/life-time-deal/" }) {
                childImageSharp {
                  gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }
        }
    `)

    const cta = getImage(data.HomeCTA)

    const Slides = [
        {
            cite: `
                <cite className="elementor-testimonial__cite"><span className="elementor-testimonial__name">Jack Armstrong</span><span className="elementor-testimonial__company"> - Air Pixels</span><span className="elementor-testimonial__title">Great product!</span></cite>
            `,
            content: `
                <div className="swiper-slide-contents">
                <div className="elementor-testimonial__text"> Just started using Optinly to maximize return on exit intent for users leaving my site. I easily installed a popup to display discount code and I really enjoy the banners in the analytics page. I highly recommend this great deal.</div>
                </div>`,
            img: SliderImg1
        },
        {
            cite: `
                <cite className="elementor-testimonial__cite"><span className="elementor-testimonial__name">RaviPrakash </span><span className="elementor-testimonial__title">Very important product for <br>pop-ups</span></cite>
            `,
            content: `
                <div className="swiper-slide-contents"><div className="elementor-testimonial__text">
                When combined with Switchy, Optinly is a neat and solid product via which you can create all kinds of popup to capture any kind of leads, up-sales and converting a lead to customer. Don’t miss out on this deal, Grab optinly at the earliest. </div>
                </div>`,
            img: SliderImg2
        },
        {
            cite: `
                <cite className="elementor-testimonial__cite"><span className="elementor-testimonial__name">Takeo</span><span className="elementor-testimonial__title">Fits our needs well</span></cite>
            `,
            content: `
                <div className="swiper-slide-contents"><div className="elementor-testimonial__text"> I've only been using Optinly for a couple of days, but I'm already in love with it!
                Those of us who are dedicated to lead collection need tools like Optinly.
                If you really want to grow business, please start to use Optinly right now! </div></div>`,
            img: SliderImg3
        },
        {
            cite: `
            <cite className="elementor-testimonial__cite"><span className="elementor-testimonial__name">Tommmy.q</span><span className="elementor-testimonial__company"> - The Derm Detective</span><span className="elementor-testimonial__title">Better Price Than OptinMonster For Sure</span></cite>
            `,
            content: `
                <div className="swiper-slide-contents"><div className="elementor-testimonial__text"> I've been using popups for email optins in the past and found out all these popup makers are expensive. Even I've refunded OptinMonster due to high cost. Then I found that Optinly offers similar features but with a better value, so I got an unlimited deal that should pay for itself in a few years </div></div>`,
            img: SliderImg4
        },
        {
            cite: `
                <cite className="elementor-testimonial__cite"><span className="elementor-testimonial__name">Weblink</span><span className="elementor-testimonial__title">Overall seems pretty good</span></cite>
            `,
            content: `
                <div className="swiper-slide-contents"><div className="elementor-testimonial__text">Haven't gotten to dive too deep into it yet, but seems pretty good overall. I currently use OptinMonster so it's definitely not as polished as that, but OptinMonster is so overpriced! So as long as it does the job, this will be the new go-to! Thanks for the being on Appsumo! </div></div>`,
            img: SliderImg5
        },
    ];

    const Slides1 = [
        {
            img: SliderImgHook1
        },
        {
            img: SliderImgHook2
        },
        {
            img: SliderImgHook3
        },
        {
            img: SliderImgHook4
        },
        {
            img: SliderImgHook5
        },
        {
            img: SliderImgHook6
        },
        {
            img: SliderImgHook7
        },
        {
            img: SliderImgHook8
        },
        {
            img: SliderImgHook9
        },
        {
            img: SliderImgHook10
        },
        {
            img: SliderImgHook11
        },
        {
            img: SliderImgHook12
        },
    ];
    const Slides2 = [
        {
            img: SliderImgHook13
        },
        {
            img: SliderImgHook14
        },
        {
            img: SliderImgHook15
        },
        {
            img: SliderImgHook16
        },
        {
            img: SliderImgHook17
        },
        {
            img: SliderImgHook18
        },
        {
            img: SliderImgHook19
        },
        {
            img: SliderImgHook20
        },
        {
            img: SliderImgHook21
        },
        {
            img: SliderImgHook22
        },
        {
            img: SliderImgHook23
        },
        {
            img: SliderImgHook24
        },
    ];

    const whatIsOptinlyArray = [
      {
        title: 'Goal Based Templates',
        description: 'Choose a pre-built goal based popup template template or start from scratch with Customize all of the details with our no code simple drag-and-drop editor.',
        image: getImage(data.Goal)
      },
      {
        title: 'Advanced Targeting',
        description: 'To increase website conversions, use our advanced targeting and segmentation engine to expose your perfect offer to the right individuals at the right time.',
        image: getImage(data.Advance)
      },
      {
        title: 'Real Time Analytics',
        description: 'Get all the stats you need to improve your lead generation strategy, then easily split test all your ideas to keep increasing conversions.',
        image: getImage(data.Realtime)
      }
    ]

    const featuresArray = [
        {
            image: getImage(data.Feature1),
            title: 'Exit Intent Popups',
            description: 'Exit Intent Popups are used to prevent people from leaving your website. Giving them discounts, gifts, and promotional codes. Make the most of your last-minute window to increase online conversions.'
        },
        {
            image: getImage(data.Feature2),
            title: 'Floating Sidebar',
            description: 'Display completely non-obtrusive popups on your webpage. And make them visible when visitors scroll throughout. Floating sidebar popups are best to display important announcements & offers.'
        },
        {
            image: getImage(data.Feature3),
            title: 'Gamification Popups',
            description: 'Gamification popups to engage users and increase conversions. When it comes to attracting first-time website users, game popups come in helpful. Optinly offers a wide variety of game popups to keep your website visitors engaged.'
        },
        {
            image: getImage(data.Feature4),
            title: 'Notification Popups',
            description: 'Use popup notifications to let your visitors know about your new eBooks, case studies, or upcoming webinars. Notification popups are perfect when you want to grab the instant attention of your website visitors.'
        },
        {
            image: getImage(data.Feature5),
            title: 'FullScreen Overlays',
            description: "Obtain the visitor's entire attention. Fullscreen Overlays are ideal for informing your visitors about new product launches, exclusive deals, industry news, and more - it's a win-win situation."
        },
        {
            image: getImage(data.Feature6),
            title: 'Timed Delay Popups',
            description: 'Display popups to your visitors just when they are needed. Use timed delay popups to deliver messages at the proper time and efficiently grow your email list. Timed delay popups were determined to be effective by 8 out of 10 websites that utilized them.'
        },
    ]

    const testimonialNew = [
        {
            name: 'Jofra Archer',
            review: 'All the features you need and none you don’t. The simplicity of the tool, helped us become more productive and our customer satisfaction rates skyrocketed',
            image: getImage(data.TestimonialNew),
            details: 'ASB Dealers'
        },
        {
            name: 'Jofra Archer',
            review: 'All the features you need and none you don’t. The simplicity of the tool, helped us become more productive and our customer satisfaction rates skyrocketed',
            image: getImage(data.TestimonialNew),
            details: 'ASB Dealers'
        },
        {
            name: 'Jofra Archer',
            review: 'All the features you need and none you don’t. The simplicity of the tool, helped us become more productive and our customer satisfaction rates skyrocketed',
            image: getImage(data.TestimonialNew),
            details: 'ASB Dealers'
        },
    ]

    const pricelist = [
        {
          plan: "Opinly Plus",
          price: "199",
          dummyPrice: '2399',
          benefits: [
            "No monthly / yearly fees",
            "100,000 views / month",
            "Personalized for your website",
            "3 Websites ",
            "unlimited popups",
            "Unlimited Targeting Rules ",
            "100+ ready to deploy Premium Templates",
            "Spin the Wheel Popups",
            "Popup Launcher",
            "Fullscreen Overlays",
            "Countdown Timer Popups",
            "Drag and Drop Editor",
            "30+ Email Marketing and CRM integrations",
            "Priority Support on live chat and email ",
            "Unlimited campaigns & leads",
            "A/B testing & Scheduling"
          ],
        },
        {
            plan: "Optinly Ultimate",
            price: "499",
            dummyPrice: '5499',
            benefits: [
              "No monthly / yearly fees",
              "300,000 views / month",
              "Personalized for your website",
              "15 Websites",
              "unlimited popups",
              "Unlimited Targeting Rules",
              "100+ ready to deploy Premium Templates",
              "Spin the Wheel Popups",
              "Popup Launcher",
              "Fullscreen Overlays",
              "Countdown Timer Popups",
              "Drag and Drop Editor",
              "30+ Email Marketing and CRM integrations",
              "Priority Support on live chat and email ",
              "Unlimited campaigns & leads",
              "A/B testing & Scheduling"
            ],
          },
      ];

      const videoArray = [
        {
            id:1,
            link: 'P3DibI8o7p8',
            image: getImage(data.Thumbnail1),
            name: 'Optinly Review - Make your email list explode with campaigns',
            channel: 'Software Reviews with Phillip Stemann'
        },
        {
            id:2,
            link: '8dDjWr3TKCc',
            image: getImage(data.Thumbnail2),
            name: 'Optinly Review - An Honest Optinly Review',
            channel: 'Saas Reviews'
        },
        {
            id:3,
            link: 'CnP0TgyffIU',
            image: getImage(data.Thumbnail3),
            name: 'How I generate new leads for HighLevel from WordPress - Optinly Review',
            channel: 'Sell Your Service'
        },
        {
            id:4,
            link: 'MUSbRuUJjDI',
            image: getImage(data.Thumbnail4),
            name: 'Optinly Walkthrough – Smarter Website Popups To Drive Conversions',
            channel: 'Christopher Walker'
        },
      ]

        const insightIcon01 = getImage(data.InsightIcon01);
        const insightIcon02 = getImage(data.InsightIcon02);
        const insightIcon03 = getImage(data.InsightIcon03);

        const itemsList = [
            {
            title: "Drag & Drop Builder",
            paragraph:
                "A fully visual page builder with beautiful & functional pre-built templates. No coding required",
            Icon: insightIcon01,
            },
            {
            title: "A/B Testing",
            paragraph:
                "You can make data-driven decisions that will boost your conversions and revenue for any campaign with the built-in A/B testing tools.",
            Icon: insightIcon02,
            },
            {
            title: "190+ Templates",
            paragraph:
                "To help you supercharge your marketing campaigns, use Optinly's 190+ attractive popup templates, which have been handmade by experts.",
            Icon: insightIcon03,
            }
        ];

      const [activeVideoId, setActiveVideoId] = useState(videoArray[0].link)

    return (
        <>         
            <LifetimeDealLayout isIntegrationPage={false}>

                <Metatags
                    title="Optinly Lifetime Deal - Get It Now"
                    description="Get the Optinly lifetime deal and capture leads better, get our priority support, latest updates, and everything forever with no hidden or extra charges."
                />

                {/* Home Section */}
                    <section className='w-full home-banner-background'>
                        <div className='w-4/5 pb-8 mx-auto pt-28'>
                            <div className='flex flex-col xl:flex-row xl:items-center xl:space-x-10'>

                                <div className='w-full mx-auto xl:w-1/2'>
                                    <div className='flex flex-col space-y-3'>
                                        <h1 className='text-2xl font-semibold text-dark_black md:text-3xl xl:text-4xl'>Optinly Lifetime Deal</h1>
                                        <p className='text-base font-normal text-dark_black md:text-lg xl:text-xl'>Optinly makes it simple to create goal-based website popups with specific triggers in order to engage visitors and grow your customer base.</p>
                                    </div>
                                    <div className='w-full px-5 py-2 my-10 text-center xl:w-3/5 md:px-10 xl:px-20 rounded-10px bg-primary'>
                                        <a href='https://ltd.optinly.com/?plan=Plus' target="_blank" className='text-lg font-semibold text-center text-white md:text-xl xl:text-2xl'>Get The Deal Now</a>
                                    </div>

                                    <div className='flex flex-col w-full space-y-2 xl:w-3/4'>
                                        <div className='w-full h-3 bg-gray-300 rounded-xl'>
                                            <div className='w-11/12 h-3 transition duration-500 ease-in-out rounded-xl bg-primary'></div>
                                        </div>
                                        <div className='flex flex-row items-center justify-between'>
                                            <div className='text-base font-normal text-dark_black'>97% claimed <span className='font-medium'>(7321 Sold)</span></div>
                                            <Timer date={targetDate} />
                                        </div>
                                    </div>
                                </div>

                                <div className='flex justify-center w-full mx-auto xl:w-1/2'>
                                    <GatsbyImage image={cta} alt="cjco.com review" />
                                </div>
                            </div>

                        </div>
                    </section>

                    {/* Rating Section */}
                    <section className='w-full mx-auto'>
                        <div className='flex items-center justify-center w-4/5 pt-10 mx-auto'>
                            <GatsbyImage image={getImage(data.Rating)} alt="Ratings on different platforms" />
                        </div>    
                    </section>

                    {/* What is Optinly */}
                    <Features />
                    

                    {/* Testimonial section */}
                    <div className="lifetime-deal-popup-testimonial-section">
                        <div className="container">
                            <div className="mb-5 space-y-6 text-center hero-content wow fadeIn">
                                <h2 className="text-xl font-semibold text-center capitalize text-logo_blue md:text-2xl xl:text-3xl 2xl:text-4xl">See why 5000+ customers love using Optinly</h2>
                                <p className="text-sm font-normal md:text-base lg:text-lg">Optinly leads the pack when it comes to capturing leads using popups and that’s why our customers love it.</p>
                            </div>
                            <div className="pt-3 position-relative">
                                <SwiperSlider slidesPerView={3} slides={Slides} spaceBetween={40} slideDeley={5000} />
                            </div>
                        </div>
                    </div>

                    {/* Video Section */}
                    <section className='w-full py-20 bg-white'>

                        <div className="w-full mx-auto xl:w-4/5">
                            <h2 className="text-xl font-semibold text-center capitalize text-logo_blue md:text-2xl xl:text-3xl">
                                Grow Your Business With Optinly
                            </h2>
                        </div>

                        {/* Desktop View */}
                        <div className='flex-row items-center hidden w-4/5 py-10 mx-auto space-x-12 xl:flex'>
                            <div id="video-container" className='w-3/5'>
                                <iframe className='hidden rounded-lg xl:block' width={videoWidth} height={videoHeight} src={`https://www.youtube.com/embed/${activeVideoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>

                            <div className='flex flex-col items-start w-2/5 space-y-8'>
                                {videoArray.map((item, index)=> {
                                    return(
                                        <>
                                        
                                            <div onClick={()=> setActiveVideoId(item.link)} className='cursor-pointer group ltd-video-card-shadow'>

                                                <div className='flex flex-row items-center space-x-3.5'>
                                                    <div className='relative w-40 h-24 rounded'>
                                                        <GatsbyImage image={item.image} alt="video image" className='relative z-0 w-40 h-24 rounded' />
                                                        <div className='absolute top-0 z-10 w-40 h-24 rounded opacity-50 group-hover:bg-black'></div>
                                                        <div className='absolute inset-0 z-20 flex items-center justify-center'>
                                                            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g filter="url(#filter0_d_122_265)">
                                                                <path d="M13.2073 0.00732422C7.42113 0.00732422 2.72513 4.70333 2.72513 10.4895C2.72513 16.2756 7.42113 20.9716 13.2073 20.9716C18.9934 20.9716 23.6894 16.2756 23.6894 10.4895C23.6894 4.70333 18.9934 0.00732422 13.2073 0.00732422ZM11.1109 15.2064V5.77251L17.4001 10.4895L11.1109 15.2064Z" fill="white"/>
                                                                </g>
                                                                <defs>
                                                                <filter id="filter0_d_122_265" x="0.725128" y="0.00732422" width="24.9643" height="24.9644" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                                <feOffset dy="2"/>
                                                                <feGaussianBlur stdDeviation="1"/>
                                                                <feComposite in2="hardAlpha" operator="out"/>
                                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0"/>
                                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_122_265"/>
                                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_122_265" result="shape"/>
                                                                </filter>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col -space-y-1.5'>
                                                        <p className='text-sm font-normal text-dark_black group-hover:text-logo_blue 2xl:text-base'>{item.name}</p>
                                                        <small className='font-normal text-font_2_dark group-hover:text-logo_blue'>{item.channel}</small>
                                                    </div>
                                                </div>

                                            </div>
                                            
                                        </>
                                    )
                                })}
                            </div>
                        </div>

                        {/* Mobile View */}
                        <div className='flex-col items-center w-4/5 py-10 mx-auto space-y-10 xl:hidden fex'>
                            <div id="video-container" className='w-full mx-auto md:flex md:justify-center'>
                                <iframe className='block rounded-md md:hidden' width="300" height="180" src={`https://www.youtube.com/embed/${activeVideoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                <iframe className='hidden rounded-md md:block' width="500" height="280" src={`https://www.youtube.com/embed/${activeVideoId}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            <div className='flex flex-col items-start w-full space-y-8'>
                                {videoArray.map((item, index)=> {
                                    return(
                                        <>
                                        
                                            <div onClick={()=> setActiveVideoId(item.link)} className='cursor-pointer group ltd-video-card-shadow'>

                                                <div className='flex flex-row items-center space-x-3.5'>
                                                    <div className='relative w-40 h-24 rounded'>
                                                        <GatsbyImage image={item.image} alt="video image" className='relative z-0 w-40 h-24 rounded' />
                                                        <div className='absolute top-0 z-10 w-40 h-24 rounded opacity-50 group-hover:bg-black'></div>
                                                        <div className='absolute inset-0 z-20 flex items-center justify-center'>
                                                            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g filter="url(#filter0_d_122_265)">
                                                                <path d="M13.2073 0.00732422C7.42113 0.00732422 2.72513 4.70333 2.72513 10.4895C2.72513 16.2756 7.42113 20.9716 13.2073 20.9716C18.9934 20.9716 23.6894 16.2756 23.6894 10.4895C23.6894 4.70333 18.9934 0.00732422 13.2073 0.00732422ZM11.1109 15.2064V5.77251L17.4001 10.4895L11.1109 15.2064Z" fill="white"/>
                                                                </g>
                                                                <defs>
                                                                <filter id="filter0_d_122_265" x="0.725128" y="0.00732422" width="24.9643" height="24.9644" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                                <feOffset dy="2"/>
                                                                <feGaussianBlur stdDeviation="1"/>
                                                                <feComposite in2="hardAlpha" operator="out"/>
                                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0"/>
                                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_122_265"/>
                                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_122_265" result="shape"/>
                                                                </filter>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className='flex flex-col -space-y-1.5'>
                                                        <p className='text-xs font-normal text-dark_black group-hover:text-logo_blue'>{item.name}</p>
                                                        <small className='font-normal text-font_2_dark group-hover:text-logo_blue' style={{fontSize: '10px'}}>{item.channel}</small>
                                                    </div>
                                                </div>

                                            </div>
                                            
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </section>


                    {/* Drag and drop builder */}
                    <section className='w-full py-20 bg-price_card_background'>
                        <div className="flex flex-col items-center w-4/5 mx-auto space-y-8 xl:flex-row xl:space-y-0">
                            <div className='flex justify-center w-full m-auto xl:w-1/2'>
                                <GatsbyImage image={getImage(data.DragAndDrop)} alt="drag and drop" />
                            </div>
                            <div className='flex flex-col items-start w-full mx-auto space-y-5 xl:w-1/2'>
                                <h3 className="text-xl font-semibold text-center capitalize text-logo_blue md:text-2xl xl:text-3xl">
                                    Drag and Drop Builder
                                </h3>
                                <p className='text-sm font-normal text-dark_black xl:text-base'>
                                With Optinly's drag-and-drop builder, you can create high-converting campaigns with no design or coding skills. Make use of our new drag-and-drop feature to fully Customize your message, colors, photos, graphics, and everything else to match the design and feel of your website.
                                </p>
                            </div>
                        </div>
                    </section>

                    {/* How can help you section */}
                    <HowOptinly />

                    {/* Features Section */}
                    <section className='w-full py-20' style={{background: '#F5F5F5'}}>
                        
                        <div className='w-4/5 mx-auto'>
                            <h2 className="text-xl font-semibold text-center capitalize text-logo_blue md:text-2xl xl:text-3xl">
                                Different Types of Popups to Double Your Conversions and Email List 
                            </h2>
                        </div>

                        <div className='grid w-4/5 grid-cols-1 gap-10 mx-auto mt-16 md:grid-cols-2 xl:grid-cols-3'>
                            {featuresArray.map((item, index) => {
                                return(
                                    <div className={`w-full`}>
                                        <div className='relative w-full mx-auto '>
                                            <div className='relative z-10'>
                                                <GatsbyImage image={item.image} alt={item.title} />
                                            </div>
                                        </div>
                                        <div className='flex flex-col items-start w-full py-10 mx-auto space-y-3'>
                                            <h4 className="text-base font-semibold text-black md:text-lg xl:text-xl">{item.title}</h4>
                                            <p className="text-sm font-normal text-left text-black md:text-base" style={{color: '#5E6063'}}>{item.description}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </section>

                    {/* Customers */}
                    <section className="relative w-full py-20 space-y-20">

                        <div className="flex flex-col items-center justify-center w-4/5 mx-auto space-y-10 xl:flex-row xl:space-y-0">
                            <div className="items-center justify-center w-full mx-auto xl:w-1/2">
                                <div className="flex flex-col items-center space-y-4 md:px-28 xl:px-32 lg:items-start">
                                    <div className="text-2xl font-semibold leading-10 text-black md:text-3xl xl:text-4xl md:leading-45px xl:leading-50px">
                                        13500+ Customers in over 120+ countries use Optinly
                                    </div>
                                    <div className="flex flex-row items-center space-x-3 space-y-3 text-6xl font-semibold text-left text-black">
                                        <span>4.7/5</span>
                                        <span className="">
                                            <svg
                                                width="27"
                                                height="25"
                                                viewBox="0 0 27 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                d="M20.7773 24.3123C20.5997 24.313 20.4264 24.2577 20.2821 24.1541L13.5 19.2372L6.71781 24.1541C6.57292 24.2592 6.39835 24.3155 6.21936 24.3149C6.04036 24.3142 5.86621 24.2566 5.72208 24.1505C5.57795 24.0444 5.47131 23.8951 5.41758 23.7244C5.36385 23.5536 5.36581 23.3702 5.42318 23.2007L8.06834 15.3659L1.21287 10.6647C1.06438 10.563 0.952302 10.4164 0.893022 10.2465C0.833741 10.0765 0.830362 9.89206 0.883378 9.72005C0.936394 9.54805 1.04303 9.3975 1.1877 9.29041C1.33236 9.18332 1.50749 9.1253 1.68748 9.12483H10.145L12.6974 1.27004C12.7523 1.10046 12.8596 0.952652 13.0038 0.847821C13.148 0.74299 13.3217 0.686523 13.5 0.686523C13.6783 0.686523 13.8519 0.74299 13.9961 0.847821C14.1403 0.952652 14.2476 1.10046 14.3026 1.27004L16.8549 9.12747H25.3125C25.4927 9.12738 25.6682 9.18499 25.8133 9.29188C25.9584 9.39876 26.0655 9.54929 26.1188 9.72143C26.1722 9.89357 26.169 10.0783 26.1098 10.2485C26.0506 10.4187 25.9384 10.5655 25.7897 10.6673L18.9316 15.3659L21.5752 23.1986C21.618 23.3254 21.6301 23.4606 21.6103 23.593C21.5906 23.7253 21.5397 23.8511 21.4617 23.96C21.3838 24.0688 21.281 24.1575 21.162 24.2187C21.043 24.28 20.9112 24.3121 20.7773 24.3123Z"
                                                fill="black"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="text-base font-normal text-black uppercase">( AVG. RATING BY OVER 1000+ USERS )</div>
                                </div>
                            </div>
                            <div className="xl:w-1/2">
                                {/* Image  Section  */}
                                <div className="flex mx-auto xl:items-center xl:justify-center">
                                <GatsbyImage image={getImage(data.Map)} alt="video" />
                                </div>
                            </div>
                        </div>

                        {/* Platforms */}
                        <div className='flex flex-col items-center justify-center w-4/5 mx-auto space-y-10 xl:flex-row xl:space-y-0'>
                            <div className='flex items-center justify-center w-full mx-auto xl:w-1/2'>
                                <GatsbyImage image={getImage(data.Platforms1)} alt="Platforms" />
                            </div>
                            <div className='w-full mx-auto text-2xl font-semibold leading-10 text-center text-black xl:w-1/2 md:text-3xl xl:text-4xl md:leading-45px xl:leading-50px'>
                                Optinly connects on over 25+ different platforms.
                            </div>
                        </div>

                    </section>

                    {/* Insights */}
                    <section className='w-full py-20 bg-price_card_background'>
                        <div className='w-4/5 mx-auto'>

                            {/* Tablet and Desktop view */}
                            <div className='relative items-center hidden md:flex'>
                                <div className='xl:pl-40'>
                                    <GatsbyImage image={getImage(data.Empty)} alt='empty' className='md:w-4/5 xl:w-full' />
                                </div>

                                <div className='absolute hidden p-6 bg-white rounded md:block md:-right-16 xl:right-80 xl:p-12 md:w-80 md:h-80 xl:w-450px xl:h-450px' style={{border: '3px solid #3E4BB1'}}>
                                    <h3 className="text-2xl font-bold uppercase text-dark_black md:text-3xl xl:text-4xl">
                                        Get all insights in one place
                                    </h3>
                                    <p className='pt-4 text-sm text-font_2_dark xl:text-base'>
                                        Get all the data you need to optimise your lead generation strategy, then effortlessly split test all of your ideas to keep conversions growing.
                                    </p>
                                    <div className='px-5 py-2 mt-4 text-center rounded bg-logo_blue xl:w-1/2'>
                                        <a href='https://ltd.optinly.com/?plan=Plus' target="_blank" className='text-sm font-bold text-white uppercase xl:text-base'>Buy Now</a>
                                    </div>
                                </div>
                            </div>
                            
                            {/* Mobile view */}
                            <div className='relative flex flex-col items-center md:hidden space-y-7'>
                                <div className=''>
                                    <GatsbyImage image={getImage(data.Empty)} alt='empty' className='' />
                                </div>

                                <div className='block p-6 bg-white rounded md:hidden' style={{border: '3px solid #3E4BB1'}}>
                                    <h3 className="text-2xl font-bold uppercase text-dark_black">
                                        Get all insights in one place
                                    </h3>
                                    <p className='pt-4 text-sm text-font_2_dark xl:text-base'>
                                        Get all the data you need to optimise your lead generation strategy, then effortlessly split test all of your ideas to keep conversions growing.
                                    </p>
                                    <div className='px-5 py-2 mt-4 text-center rounded bg-logo_blue xl:w-1/2'>
                                        <a href='https://ltd.optinly.com/?plan=Plus' target="_blank" className='text-sm font-bold text-white uppercase xl:text-base'>Buy Now</a>
                                    </div>
                                </div>
                            </div>

                            <div className="grid items-center justify-center w-4/5 grid-cols-1 gap-10 pt-20 mx-auto space-x-1 space-y-4 xl:grid-cols-3 gap-y-16">
                                {itemsList.map((item, index) => {
                                    return (
                                    <div
                                        className="flex flex-col items-center w-full space-y-5"
                                        key={index}
                                    >
                                        <div className="flex mx-auto w-14 xl:items-center xl:justify-center">
                                        <GatsbyImage image={item.Icon} alt="icon" />
                                        </div>
                                        <div className="items-center justify-center w-full space-x-5 space-y-4 text-center ">
                                        <h3 className="text-sm font-semibold text-black md:text-base xl:text-lg">{item.title}</h3>
                                        <p className="w-5/6 text-base text-black">{item.paragraph}</p>
                                        </div>
                                    </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>

                    {/* Integration section */}
                    <div className="integration-popup-clients-section">
                
                        <div className="container">
                            <div className="mb-5 text-center hero-content wow fadeIn">
                                <h2 className="text-xl font-semibold text-center capitalize text-logo_blue md:text-2xl xl:text-3xl 2xl:text-4xl">Integrate With Top 20+ ESPs and Webhooks</h2>
                                <p className="text-sm font-normal md:text-base lg:text-lg">Collect your captured email leads in one place. Integrate with some of the best ESPs and webhooks in the industry <br/> seamlessly. And carry out multiple actions in autopilot.</p>
                            </div>
                            <div className="pt-3 position-relative">
                                <SwiperSlider slidesPerView={8} slides={Slides1} spaceBetween={0} slideDeley={3000} />
                                <SwiperSlider slidesPerView={8} slides={Slides2} spaceBetween={0} slideDeley={3000} dir="rtl" />
                            </div>
                        </div>
                    </div>

                    {/* Pricing Section */}
                    <section className="relative pt-14 lg:pt-24 pb-14 lg:pb-20 bg-price_card_background">
                        {/* Title Section */}
                        <div className="flex flex-col items-center mx-auto space-y-2 text-center">
                            <h2 className="w-4/5 mx-auto text-xl font-semibold capitalize lg:text-3xl 2xl:text-4xl text-logo_blue">
                                Chose the deal that is right for you
                            </h2>
                            <p className="text-sm font-normal md:text-base lg:text-lg">60 days no questions asked refund policy</p>
                            <small className='text-xs text-dark_blue opacity-80'>One time payment, no recurring fees</small>
                        </div>

                        {/* price Card Section  */}
                        <div className="w-full xl:pt-12">
                            <div className="flex flex-col items-center justify-center w-4/5 mx-auto lg:flex-row lg:space-x-16 xl:space-x-20 ">
                                {pricelist.map((price, index) => {
                                return (
                                    <div
                                    id="ltd-pricing"
                                    className={`flex flex-col mt-10 rounded-md bg-white hover:bg-white ltd-price-box-shadow `}
                                    key={index}
                                    >
                                        <div className="px-10 py-4">

                                            <div className="px-2 py-4 text-xl text-black font-semibold text-left flex flex-col space-y-1.5">
                                                <span>{price.plan}</span>
                                                <svg width="41" height="5" viewBox="0 0 41 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="41" height="5" rx="2.5" fill="#3E4BB1"/>
                                                </svg>
                                            </div>

                                            <div className="flex flex-row items-center text-3xl font-bold text-black">
                                                <span>{"$"}{price.price}</span> 
                                                <span className='pl-3 text-base font-semibold text-red-500 line-through'>{"$"}{price.dummyPrice}</span>
                                            </div>

                                            <span className='text-xs font-medium text-font_secondary opacity-80'>One time purchase</span>

                                            <div className="w-full mt-4 text-center">
                                                <a href={`https://ltd.optinly.com/?plan=${price.plan.split(' ')[1]}`} target="_blank" className="inline-block w-full px-10 py-3 text-sm font-bold text-center text-white uppercase rounded hover:text-white hover:no-underline" style={{ backgroundColor: "#3E4BB1" }}>
                                                    BUY NOW
                                                </a>
                                            </div>

                                            {price.benefits.map((benefit, index) => {
                                            return (
                                                <div className="pt-4 space-y-3 text-left" key={index}>
                                                    <div className="flex flex-row items-center space-x-2 ">
                                                        <div className="px-0">
                                                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="10" cy="10.3564" r="10" fill="#3E4BB1"/>
                                                                <path d="M8.33333 11.6816L13.825 6.18994L15 7.37327L8.33333 14.0399L5 10.7066L6.175 9.53161L8.33333 11.6816Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                        <div className="px-1 text-sm font-normal " style={{ color: "#5E6063" }} >
                                                            {benefit}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                            })}
                                        </div>

                                    </div>
                                );
                                })}
                            </div>
                        </div>
                    </section>
            </LifetimeDealLayout>
        </>
    )
}

export default LifeTimeDeal
