import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const HowOptinly: React.FC = () => {
  const HowOptinlyQueryData = useStaticQuery(graphql`
    query HowOptinlyQuery {
      Icon1: file(name: { eq: "01" }, absolutePath: { regex: "/images/life-time-deal/howoptinly/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Icon2: file(name: { eq: "02" }, absolutePath: { regex: "/images/life-time-deal/howoptinly/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Icon3: file(name: { eq: "03" }, absolutePath: { regex: "/images/life-time-deal/howoptinly/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Icon4: file(name: { eq: "04" }, absolutePath: { regex: "/images/life-time-deal/howoptinly/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Icon5: file(name: { eq: "05" }, absolutePath: { regex: "/images/life-time-deal/howoptinly/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Icon6: file(name: { eq: "06" }, absolutePath: { regex: "/images/life-time-deal/howoptinly/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }

      Top: file(
        name: { eq: "Ellipse-1" }
        absolutePath: { regex: "/images/" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }
      Bottom: file(
        name: { eq: "Ellipse-2" }
        absolutePath: { regex: "/images/" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, quality: 100)
        }
      }
    }
  `);

  const icon1 = getImage(HowOptinlyQueryData.Icon1);
  const icon2 = getImage(HowOptinlyQueryData.Icon2);
  const icon3 = getImage(HowOptinlyQueryData.Icon3);
  const icon4 = getImage(HowOptinlyQueryData.Icon4);
  const icon5 = getImage(HowOptinlyQueryData.Icon5);
  const icon6 = getImage(HowOptinlyQueryData.Icon6);


  const itemsList = [
    {
      title: "Grab Visitor’s Attention",
      paragraph:
        "Show visitors what they need - at the right time and at the right place",
      Icon: icon1,
    },
    {
      title: "Show visitors what they need",
      paragraph:
        "Make visitors take desired actions and achieve your business goals",
      Icon: icon2,
    },
    {
      title: "Collect Feedback",
      paragraph:
        "Know what website visitors think about your brand & your store",
      Icon: icon3,
    },
    {
      title: "Promote Special Discounts",
      paragraph:
        "Run discount campaigns using pre-built popup templates",
      Icon: icon4,
    },
    {
      title: "Conduct Surveys",
      paragraph:
        "Make wise decisions for your store’s growth by conducting online surveys",
      Icon: icon5,
    },
    {
      title: "Grow Your Email List",
      paragraph:
        "Capture more visitors and grow your email list by displaying popups",
      Icon: icon6,
    },
  ];

  return (
    <>
      <section className="relative bg-white py-14 xl:py-24">
        {/* Title Section */}
        <div className="mx-auto space-y-10 text-center">
          <h2 className="w-4/5 mx-auto text-xl font-semibold capitalize lg:text-3xl 2xl:text-4xl text-logo_blue">
            How Optinly Can Help Grow Your Businesses     
          </h2>
        </div>

        <div className="grid items-center justify-center w-4/5 grid-cols-1 gap-10 pt-10 mx-auto space-x-1 space-y-4 md:grid-cols-2 xl:grid-cols-3 md:gap-x-10 gap-y-16 md:gap-y-7">
          {itemsList.map((item, index) => {
            return (
              <div
                className="flex flex-col items-center w-full space-y-5"
                key={index}
              >
                <div className="flex mx-auto w-14 xl:items-center xl:justify-center">
                  <GatsbyImage image={item.Icon} alt="icon" />
                </div>
                <div className="items-center justify-center w-full space-x-5 space-y-4 text-center ">
                  <h3 className="text-sm font-semibold text-black md:text-base xl:text-lg">{item.title}</h3>
                  <p className="w-5/6 text-base text-black">{item.paragraph}</p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default HowOptinly;
