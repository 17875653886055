export const getElementHeight = (id) => {
    const ele = document.getElementById(id)
    console.log('height', ele ? ele.clientHeight : id)
    return ele ? ele.clientHeight : 'auto'
}

export const getElementWidth = (id) => {
    const ele = document.getElementById(id)
    console.log('width', ele ? ele.offsetWidth : id)
    return ele ? ele.offsetWidth : 'auto'
}