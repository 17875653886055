import { Link, navigate } from 'gatsby'
import React, { useState } from 'react'
import { MdMenu } from 'react-icons/md'
import Logo from '../images/Optinly.svg'
import { getElementHeight } from '../utils/elementHeight'

const LifeTimeDealHeader = () => {

    const [showDropDown, setShowDropDown] = useState(false);

    const handleDropDown = () => {
        setShowDropDown(!showDropDown)
    };

  return (
    <>
        <div className='w-full py-4 bg-white fixed top-0' style={{
            boxShadow: '0 2px 3px 0 rgba(96, 96, 96, .1)',
            transition: '0.5s all ease',
            boxShadow: 'none',
            zIndex: 1030
        }}>
            <div className='w-3/4 mx-auto flex items-center justify-between'>
                <div className=' xl:w-1/3 inline-flex items-center space-x-3 justify-start'>
                    <img src={ Logo } alt="Optinly" /> <span className='text-2xl font-bold' style={{color: '#383636'}}>Optinly</span>
                </div>

                <div className='block xl:hidden'>
                    <button id="showMenuBtn" onClick={handleDropDown} className="visible-xs btn btn-primary"><MdMenu/></button>
                </div>

                <Link to='/lifetime-deal/#ltd-pricing' className='hidden xl:w-1/3 xl:flex justify-center mx-auto text-base font-semibold cursor-pointer ' style={{color: '#383636'}}>
                    Pricing
                </Link>

                <div className='hidden xl:w-1/3 xl:flex flex-row items-center space-x-4 justify-end'>
                    <a href="https://app.optinly.com/login/" target="_blank" className="bg-primary text-white px-5 py-2 rounded-md hover:shadow-xl  text-center font-semibold ">Login</a>
                    <a href="https://app.optinly.com/" target="_blank" className="bg-custom_yellow text-black px-5 py-2 rounded-md hover:shadow-xl transform hover:scale-110 transition duration-300 ease-in-out text-center font-semibold">Sign up</a>
                </div>
            </div>
            
            <div className='w-full md:w-3/4 mx-auto bg-white transition-all ease-in-out duration-300 overflow-hidden' style={{height: showDropDown ? getElementHeight('ltd-layout-header-dropdown') : 0 }}>
                <div id='ltd-layout-header-dropdown' className={`px-5 pt-4 flex flex-col space-y-5 items-start ${showDropDown ? 'opacity-100' : 'opacity-0'} transition-opacity ease-out duration-300`}>
                    <Link to='/lifetime-deal/#ltd-pricing' className='text-lg font-semibold cursor-pointer ' style={{color: '#383636'}}>
                        Pricing
                    </Link>

                    <div className='flex flex-row items-center space-x-4 justify-end'>
                        <a href="https://app.optinly.com/login/" target="_blank" className="bg-primary text-white px-3 md:px-5 py-2 rounded-md hover:shadow-xl  text-center font-semibold ">Login</a>
                        <a href="https://app.optinly.com/" target="_blank" className="bg-custom_yellow text-black px-3 md:px-5 py-2 rounded-md hover:shadow-xl transform hover:scale-110 transition duration-300 ease-in-out text-center font-semibold">Sign up</a>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default LifeTimeDealHeader