import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

type DataProps = {
    review: string,
    name: string,
    details: string,
    image: any
}

const TestimonialNewCard:React.FC<DataProps> = ({review, name, details, image}) => {
  return (
    <div className='w-full md:w-3/5 xl:w-full mx-auto p-4 md:p-6 xl:px-8 bg-white'>
        <div className='flex flex-col space-y-8'>
            <div className='flex flex-row justify-between items-center'>
                <GatsbyImage image={image} alt="profile" />
                <svg width="74" height="67" viewBox="0 0 74 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M44.928 0.000317917L73.728 0.000320435L73.728 35.7123C73.728 45.3123 71.808 52.6083 67.968 57.6003C63.744 62.7843 56.64 65.7603 46.656 66.5283L46.656 49.5363C50.112 49.5363 52.992 48.0963 55.296 45.2163C56.448 43.6803 57.024 40.7043 57.024 36.2883L57.024 30.8163L44.928 30.8163L44.928 0.000317917ZM2.87042e-05 0.000313989L28.8 0.000316507L28.8 35.7123C28.8 45.3123 27.072 52.3203 23.616 56.7363C19.008 62.4963 11.712 65.7603 1.72803 66.5283L1.72803 49.5363C7.68002 49.5363 11.04 46.5603 11.808 40.6083C12 39.8403 12.096 38.4003 12.096 36.2883L12.096 30.8163L2.60102e-05 30.8163L2.87042e-05 0.000313989Z" fill="#5E6063"/>
                </svg>
            </div>
            <p className='text-dark_black text-sm font-normal xl:text-base'>
                {review}
            </p>
            <div className='-space-y-2'>
                <p className='text-sm xl:text-base text-dark_black font-bold'>{name}</p>
                <p className='text-xs xl:text-sm text-dark_black font-normal'>{details}</p>
            </div>
        </div>
    </div>
  )
}

export default TestimonialNewCard